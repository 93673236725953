.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #5cb85c !important;
  border-left: 3px solid #fff;
}

.ant-menu-inline .ant-menu-item {
  margin: 0 !important;
}

.header-title {
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 0 !important;
}

.ant-picker.ant-picker-disabled .ant-picker-input > input[disabled],
.ant-input.ant-input-disabled,
.ant-input-number-disabled .ant-input-number-input,
.ant-select-disabled .ant-select-selection-item {
  color: #292b2c !important;
  font-weight: 600;
}

.container {
  box-shadow: 2px 2px 19px -6px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 2px 2px 19px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 2px 19px -6px rgba(0, 0, 0, 0.75);
}

.text-success {
  color: #5cb85c;
  font-weight: 700;
  margin-bottom: 0px;
}

.text-danger {
  color: #d9534f;
  font-weight: 700;
  margin-bottom: 0px;
}

/* width and height*/
::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.ant-menu-submenu .ant-menu-submenu-title:hover,
.ant-menu-item:hover {
  padding-left: 28px !important;
}
.ant-menu-sub .ant-menu-item:hover {
  padding-left: 51px !important;
}
.label-required::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.label-required::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
